<template>
  <v-row class="justify-end">
    <v-col
      cols="12"
      sm="4"
      md="3"
      xl="2"
      ><default-button disabled>Criar Perfil</default-button></v-col
    >
  </v-row>
</template>

<script>
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";

export default {
  name: "TheHeader",
  components: {
    DefaultButton,
  },
};
</script>

<style scoped></style>
