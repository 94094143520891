<template>
  <default-table-body-row
    align="center"
    class="text-left"
  >
    <v-col
      class="py-5"
      cols="9"
    >
      <span
        :title="role | formatPerfil"
        class="font-weight-bold"
        >{{ role | formatPerfil }}</span
      >
    </v-col>
    <v-col
      cols="3"
      class="text-end"
    >
      <default-fab
        disabled
        :top="false"
        :left="true"
        tooltip-text="Editar Perfil"
        ><v-icon>mdi-pencil</v-icon></default-fab
      >
    </v-col>
  </default-table-body-row>
</template>

<script>
import DefaultFab from "@/shared/components/vuetify/DefaultFab.vue";
import DefaultTableBodyRow from "@/shared/components/vuetify/DefaultTableBodyRow.vue";

export default {
  name: "ListingRow",
  components: {
    DefaultFab,
    DefaultTableBodyRow,
  },
  props: {
    role: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>

<style scoped></style>
