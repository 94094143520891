<template>
  <div>
    <template v-if="!getIsLoading">
      <template v-if="getRoles.length > 0">
        <v-row
          dense
          class="mt-4"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            v-for="(role, index) in getRoles"
            :key="index"
          >
            <listing-row
              :role="role"
              class="mt-n2"
            />
          </v-col>
        </v-row>
        <default-pagination
          v-model="getUrlParams.page"
          :length="getPagination.lastPage"
          @input="changePage"
        />
      </template>
    </template>
    <default-spinner v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultPagination from "@/shared/components/vuetify/DefaultPagination.vue";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import ListingRow from "./ListingRow.vue";

export default {
  name: "TheListing",
  components: {
    DefaultPagination,
    DefaultSpinner,
    ListingRow,
  },
  created() {
    this.resetUrlParams();
    this.roles();
  },
  methods: {
    ...mapActions("roles", ["roles", "resetUrlParams"]),
    changePage() {
      this.roles();
    },
  },
  computed: {
    ...mapGetters("roles", [
      "getRoles",
      "getIsLoading",
      "getPagination",
      "getUrlParams",
    ]),
  },
};
</script>
